import React from "react";
import CookieConsent from "react-cookie-consent";

const CookieConsentMam = () => {
  return (
    <CookieConsent>
      This website uses cookies to enhance the user experience.
    </CookieConsent>
  );
};

export default CookieConsentMam;
