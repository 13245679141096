import React from "react";
import "./LinkList.css";
import Link from "../Link/Link";
import SocialIcon from "../SocialIcon/SocialIcon";
import Profile from "../Profile/Profile";

//icons from https://mui.com/material-ui/material-icons/?query=empl

function LinkList() {
  const links = [
    {
      url: "https://www.youtube.com/@BeauMiles",
      label: "Current inspiration: Beau Miles on YT",
      iconName: "tips_and_updates",
    },
    {
      url: "https://medium.com/@marc.surf74_84619",
      label:
        "Latest project: a Raspberry Pi powered picture frame for my grandparents",
      iconName: "memory",
    },
    {
      url: "https://www.airbus.com/en",
      label: "Check out my current employer: \n Airbus – Pioneering sustainable aerospace for a safe and united world",
      iconName: "badge",
    },

    // Add more links as desired
  ];

  return (
    <>
      <Profile />
      <div className="LinkList">
        {links.map((link, index) => (
          <Link
            key={index}
            url={link.url}
            label={link.label}
            iconName={link.iconName}
            i={index}
          />
        ))}
      </div>
      <SocialIcon />
    </>
  );
}

export default LinkList;
