import React from "react";
import profileImage from "../../MAM.png";
import "./Profile.css";
import { Avatar } from "@mui/material";

const Profile = () => {
  return (
    <div>
      <div className="avatar-container">
        <Avatar
          className="avatar"
          src={profileImage}
          alt="Marc"
          sx={{ height: "400px", width: "400px" }}
        />
      </div>
      <div className="profile-container">
        <h1>Hello world!</h1>
        <p>
          Find some social icons at the bottom if you want to connect! Here is a
          small collection of links i'd like to recommend to you:
        </p>
      </div>{" "}
    </div>
  );
};

export default Profile;
