import React from "react";
import "./Link.css";
import { motion } from "framer-motion";

function Link({ url, label, iconName, i }) {
  const variants = {
    visible: (i) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: i * 0.1,
        duration: 0.5,
        ease: "easeIn",
        type: "spring",
        stiffness: 50,
      },
    }),
    hidden: { opacity: 0, y: 200 },
  };

  return (
    <motion.div
      className="Link-container"
      initial="hidden"
      animate="visible"
      custom={i}
      variants={variants}
    >
      <a href={url} target="_blank" rel="noopener noreferrer" className="Link">
        <span className="material-icons">{iconName}</span>
        {label}
      </a>
    </motion.div>
  );
}

export default Link;
