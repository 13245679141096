import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LinkList from "./components/LinkList/LinkList";
import Footer from "./components/Footer/Footer";
import Imprint from "./pages/Imprint/Imprint";
import CookieConsentMam from "./components/CookieConsentMam";

function App() {
  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <h1>Marc Martens</h1>
        </header>
        <main>
          <Routes>
            <Route path="/" element={<LinkList />} />
            <Route path="/imprint" element={<Imprint />} />
          </Routes>
        </main>
        <CookieConsentMam />
        <Footer />
      </div>
    </Router>
  );
}

export default App;
