import React from "react";
import "./Footer.css";
import { Link, useLocation } from "react-router-dom";

function Footer() {
  const location = useLocation();

  let content;
  if (location.pathname.includes("imprint")) {
    content = <Link to="/">Home</Link>;
  } else {
    content = <Link to="/imprint">Imprint</Link>;
  }

  return <footer className="Footer">{content}</footer>;
}

export default Footer;
